<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue-demi'

const props = defineProps({
  view: {
    type: String as PropType<string>,
    default: 'primary',
    validator(value: string): boolean {
      return ['primary', 'secondary', 'text'].includes(value.toLowerCase())
    },
  },

  size: {
    type: Number as PropType<number>,
    default: 100,
    validator(value: number): boolean {
      return [100, 50].includes(value)
    },
  },

  fitContent: Boolean as PropType<boolean>,

  rounded: Boolean as PropType<boolean>,

  asLink: Boolean as PropType<boolean>,

  data: {
    type: Object as PropType<object>,
    required: false,
    default: () => ({}),
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const classes = computed(() => ({
  [`cf-button--${props.view}`]: props.view,
  [`cf-button--${props.size}`]: props.size,
  'cf-button--fit-content': props.fitContent,
  'cf-button--rounded': props.rounded,
  'cf-button--disabled': props.disabled,
}))

const isLink = computed(() => props.asLink ? 'a' : 'button')

defineExpose({
  isLink,
  classes,
})
</script>

<template>
  <component :is="isLink" class="cf-button" type="button" v-bind="$attrs" :class="classes">
    <slot name="default" v-bind="data" />
  </component>
</template>

<style lang="scss">
.cf-button {
  position: relative;
  font-weight: 700;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;

  & > svg:not(.inline) {
    width: 100%;
    height: 100%;
  }

  &--100 {
    padding: 8px 16px;
  }

  &--50 {
    padding: 8px 16px;
    height: 40px;

  }

  &--fit-content {
    width: auto;
  }

  &--primary {
    color: #ffffff;
    background: #F00020;

  }

  &--secondary {
    color: #222222;
    background: #E6E6E6;
  }

  &--text {
    background: transparent;
    color: #008FFD
  }

  &--rounded {
    padding: 8px;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
  }

  &--disabled {
    background-color: #E6E6E6;
    color: #C4C4C4;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:disabled {
    color: #777777;
    cursor: default;
  }
}
</style>
