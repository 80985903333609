<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue-demi'

const props = defineProps({
  isOpen: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  height: {
    type: String,
    default: '500px',
  },
  width: {
    type: String,
    required: false,
  },
  headerBackground: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['open'])

function handleCloseModal() {
  emit('open', !props.isOpen)
}
const backgroundTypeClass = computed<string[]>(
  () => {
    const classes = ['info', 'error', 'warning', 'success'].includes(props.headerBackground)
      ? `cf-modal__header-bg-${props.headerBackground}`
      : null
    return [classes].filter(Boolean) as string[]
  },
)

defineExpose({
  handleCloseModal,
  backgroundTypeClass,
})
</script>

<template>
  <transition name="cf-modal">
    <div
      v-show="isOpen"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      class="cf-modal cf-modal--fixed cf-modal--backdrop"
      @click.self="handleCloseModal"
    >
      <div class="cf-modal__container">
        <header id="modalTitle" class="cf-modal__header" :class="backgroundTypeClass">
          <slot name="header" :close="handleCloseModal" />
        </header>

        <section id="modalDescription" class="cf-modal__content">
          <slot name="default" />
        </section>

        <nav class="cf-modal__navigation">
          <hr>
          <slot name="navigation" :close="handleCloseModal" />
        </nav>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.cf-modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 2001;

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.15s ease;
  }

  &--fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &--backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__container {
    position: absolute;
    display: inherit;
    flex-direction: column;
    min-width: 300px;
    width: v-bind('props.width');
    min-height: v-bind('props.height');
    max-width: 700px;
    max-height: 100%;
    background: white;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50px;
    font-weight: bold !important;
    padding-left: 16px;
    padding-right: 16px;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }

    &-bg {
      &-info {
        color: #ffffff;
      }

      &-success {
        background: #50CC7F;
        color: #ffffff;
      }

      &-error {
        background: #F24E63;
        color: #ffffff;

      }

      &-warning {
        background: #FFDF33;
        color: #000000;
      }
    }

  }

  &__content {
    flex: auto;
    display: inherit;
    padding: 16px;
  }

  &__navigation {
    display: inherit;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border-color: #C4C4C4;
    padding: 16px;

    & hr {
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0;
      margin: 0;
      border: none;
      height: 1px;
      background: #C4C4C4;
    }
  }
}
</style>
